import React from "react";
import DevelopmentPageTemplate from "../../templates/development-page";
import { Nav } from "react-bootstrap";
import "../../threeplay.scss";

export default class Index extends React.Component {
  render() {
    var overview = (
      <>
        <p>
          Tabs are a navigation structure that allows users to navigate easily between views within the same context. They should be used sparingly and the content within each tab should be related.
        </p>
      </>
    );

    var codeString = `
    <Nav justify variant="tabs">
      <Nav.Item>
        <Nav.Link eventKey="tab1">
          Tab 1
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="tab2">
          Tab 2
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="tab3" >
          Tab 3
        </Nav.Link>
      </Nav.Item>
    </Nav>
    `;

    var code = (
      <>
        <br />
        <Nav justify variant="tabs">
          <Nav.Item>
            <Nav.Link eventKey="tab1">
              Tab 1
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="tab2">
              Tab 2
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="tab3" >
              Tab 3
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <br />
      </>
    );

    var codes = [
      {
        code: code,
        codeLanguage: "html",
        codeString: codeString,
      },
    ];

    var accessibilitySection = (
      <>
        <h5>Tabs should contain a unique title.</h5>
        <p>
          Each tab must have a unique title that clearly describes tab panel content. This is particularly helpful for users of assistive technologies so they have the necessary information to efficiently navigate the content.
        </p>

        <h5>Content in the tab must be accessible.</h5>
        <p>
          Content authors need to ensure the content that is added to the tab panel is accessible. For example, if you add an image to the panel you need to include alternative text to pass accessibility testing.
        </p>
      </>
    );

    return (
      <DevelopmentPageTemplate
        title="Tabs"
        overview={overview}
        codes={codes}
        accessibilitySection={accessibilitySection}
      />
    );
  }
}
