import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout";
import { Tab, Tabs } from "react-bootstrap";
import Helmet from "react-helmet";
import './../threeplay.scss'

function DevelopmentPageTemplate(props) {
  return (
    <Layout>
      <Helmet>
        <title>Development - {props.title}</title>
      </Helmet>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <h1 className="title is-size-3 has-text-weight-bold is-bold-light">{props.title}</h1>
                {props.overview}
                {
                  props.codes.map((object, index) => {
                    return (
                      <>
                        <div>
                          {object.code}
                        </div>
                        <Tabs key={index} defaultActiveKey="usage" id="uncontrolled-tab-example">
                          <Tab eventKey="usage" title="Usage">
                            <pre className="m-0">
                              <code className={object.codeString ? `language-${object.codeLanguage}` : `language-html`} id="codeBlock">
                                {object.codeString}
                              </code>
                            </pre>
                          </Tab>
                        </Tabs>
                        <hr />
                      </>
                    )                  
                  })
                }
                {props.accessibilitySection &&
                  <>
                  <h3 className="title">Accessibility</h3>
                  {props.accessibilitySection}
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

DevelopmentPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
};

export default DevelopmentPageTemplate;